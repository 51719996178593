<template>
    <div class="content-1">
        <BackTop :height="400"></BackTop>
        <Layout style="height: 100%">
            <div class="bg-content"></div>
            <Affix :offset-top="0">
                <Header :class="header_bg">
                    <Menu mode="horizontal" theme="dark" style="background-color: transparent;float: right"
                          @on-select="onMenuSelect">
                        <div class="layout-logo" style="position: fixed; left: 45px;top: 10px">
                            <a href="/"><img src="../assets/images/cui.png" style="width:55px;height: 40px"></a>
                        </div>
                        <div class="layout-nav">
                            <MenuItem name="1">
                                <a href="#products">
                                    <Icon type="ios-keypad"></Icon>
                                    Products</a>
                            </MenuItem>
                            <MenuItem name="2">
                                <a href="#support">
                                    <Icon type="ios-analytics"></Icon>
                                    Support</a>
                            </MenuItem>
                            <MenuItem name="3">
                                <a href="#experence">
                                    <Icon type="ios-paper"></Icon>
                                    Experience</a>
                            </MenuItem>
                            <MenuItem name="4">
                                <a href="#about_us">
                                    <Icon type="ios-navigate"></Icon>
                                    About Us</a>
                            </MenuItem>
                            <MenuItem name="5">
                                <a href="#contactus">
                                    <Icon type="md-pulse"></Icon>
                                    Contact Us</a>
                            </MenuItem>
                        </div>
                    </Menu>
                </Header>
            </Affix>
            <router-view></router-view>
        </Layout>
        <!--<div style="height: 100vh"></div>-->
        <div id="products"></div>
        <div style="top: 100%; background-color: white;">
            <Card :bordered="false" class="card-content" @mouseover="carousel_change">
                <Divider><h1>Products</h1></Divider>
                <p><span style="font-weight: bolder;font-family: Castellar,serif">ZLOG</span>’s 3E products focus on Natural Cooling Power train
                    solutions, including Natural Cooling Motor, Motor Controller and Battery
                    System. Voltage cover 72V to 350V, Peak Power up to 30KW, Peak Torque to 140N-M, Peak Speed Up to 10000rpm. The system includes
                    key function safety features.
                </p>
                <br>
                <Carousel :autoplay="autop_product"
                          :autoplay-speed="3000"
                          v-model="carousel_product"
                          loop
                          @mouseover.native="autop_product = false"
                          @mouseleave.native="autop_product = true"
                          dots="outside">
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 3020 Series Motors</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product1.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 7020 Series MCU/VMCU</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product2.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 3035 Series Motors</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product3.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 7035 Series MCU</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product4.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 2071 Series Battery System</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product5.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>The 2107 Series Battery System</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/product6.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                </Carousel>
                <div id="support"></div>
            </Card>
            <Card :bordered="false" class="card-content">
                <Divider><h1>Support</h1></Divider>
                <br>
                <Carousel :autoplay="autop_support"
                          :autoplay-speed="3000"
                          v-model="carousel_support"
                          loop
                          :radius-dot="true"
                          @mouseover.native="autop_support = false"
                          @mouseleave.native="autop_support = true"
                          dots="outside">
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>ZLOG offer full-stack expertise support</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/support1.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>Comprehensive Competency Area</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/support2.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>Self-Design, Customer-Application-Oriented Tow-Bench</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/support3.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>Extremely Reliability Long-Term Continuous Testing</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/support4.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                    <CarouselItem>
                        <div class="demo-carousel">
                            <h2>Intelligence Data Collection/Analysis/Report Testing System</h2>
                            <br>
                            <Row>
                                <img src="../assets/images/support5.jpg" width="100%"/>
                            </Row>
                        </div>
                    </CarouselItem>
                </Carousel>
                <div id="experence"></div>
            </Card>
            <Card :bordered="false" class="card-content">
                <Divider><h1>Experience</h1></Divider>
                <br>
                <Row>
                    <Col span="12"><img src="../assets/images/experence1.jpg" width="80%"/></Col>
                    <Col span="12"><img src="../assets/images/experence2.jpg" width="78%"/></Col>
                </Row>
                <br>
                <br>
                <Row>
                    <Col span="24"><img src="../assets/images/experence3.jpg" width="90%"/></Col>
                </Row>
                <br>
                <Button type="info" ghost @click="modal = true" size="large" icon="md-play">An Experience Video</Button>
                <Modal  v-model="modal" style="text-align: center" title="  ">
                    <video autoplay width="60%" controls>
                        <source src="../assets/images/video.mp4" type="video/mp4">
                    </video>
                    <template #footer>
                        <Button type="success" ghost @click="modal=false">Close</Button>
                    </template>
                </Modal>
                <div id="about_us"></div>
            </Card>
            <Card :bordered="false" class="card-content">
                <Divider><h1>About Us</h1></Divider>
                <br>
                <p style="text-align: left"><span style="font-weight: bolder;font-family: Castellar,serif">ZLOG</span>, one key player as Tie-One with
                    her focus on natural cooling motor, motor controller and battery system, was established on 18th-June 2021 from XLOG who offer
                    technical solutions of high performance motor, motor controller software algorithm and tools for dominant EV player like FAW,
                    Continental etc. since Y2015. ZLOG’s core founder team comes from former Alcatel-Lucent and Tongji University, joint background,
                    experience and expertise in both Auto-Motor and Telecommunications industrials.
                </p>
                <br>
                <p style="text-align: left"><span style="font-weight: bolder;font-family: Castellar,serif">ZLOG</span> offer the “TXCU” integration
                    system that combines VCU, MCU and BMS system in one mother-micro-processor chip on single PCBA mother boards with our
                    hyper-motion-software system which can run for Any-motor; Any-control; Any-chip set, which we called the A<sup>3</sup> architecture software.
                    The XCU is coupling to our Telematic Box via one shared core of the mother processor. TXCU is an open platform for any
                    intelligence motion chassis on any application area. It is also an on-line and close-loop EV data-node for any business case
                    service.
                </p>
                <br>
                <p style="text-align: left"><span style="font-weight: bolder;font-family: Castellar,serif">ZLOG</span> has the comprehensive end to
                    end environment for simulation, design, development, manufacture and verification of our 3E (BS, Motor and MCU) products, covering DV,
                    PV and on-car road testing. ZLOG map as below.
                </p>
                <img style="height: auto;width: 100%" src="../assets/images/about.jpg" alt="">
                <br>
                <br>
                <p style="text-align: left"><span style="font-weight: bolder;font-family: Castellar,serif">ZLOG</span>’s marketing principle is more
                    honest, more open and more transparent for all of our products. Any interaction/deal with our sales/marketing staff that you do not think it is in line with our
                    marketing principle, contact the business operation VP directly <a href="mailto:gcui@zlogint.com">gcui@zlogint.com</a>
                </p>
                <br>
                <div id="contactus"></div>
                <br>
            </Card>
            <Card :bordered="false" class="card-content">
                <Divider><h1 style="color: #e5d432">Contact Us</h1></Divider>
                <h3>ZLOG 联融域 Co.,LTD | Address: ShangHai, China</h3>
                <!--                <p>Telephone: </p>-->
                <!--                <p>Fax: </p>-->
                <p>Email: <a href="mailto:gcui@zlogint.com">gcui@zlogint.com</a></p>
                <br>
                <p><a target="_blank" href="https://beian.miit.gov.cn">沪ICP备 2022025533号</a></p>
            </Card>
        </div>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex'

    export default {
        components: {},
        data()
        {
            return {
                sysTitle: 'ZLOG',
                email: '',
                header_bg: 'no-bgColor',
                carousel_product: 0,
                carousel_support: 0,
                modal: false,
                autop_product: true,
                autop_support: true
            }
        },
        computed: {},
        methods: {
            ...mapMutations(['setCollapsed']),
            onMenuSelect(name)
            {
                if (name === '6')
                {
                    scrollTo(0, 0);
                }
            },
            on_click_video()
            {
                this.video_show = true;
            },
            carousel_change()
            {
                this.autoplay = !this.autoplay;
            }
        },
        mounted()
        {
            let that = this;
            document.onscroll = function ()
            {
                that.header_bg = document.documentElement.scrollTop > 100 ? 'bgColor' : 'no-bgColor';
            }
            // this.$refs.videoRef.src = "http://iandevlin.github.io/mdn/video-player/video/tears-of-steel-battle-clip-medium.mp4";
            // this.$refs.videoRef.play();
        },
        created()
        {
        }
    }

</script>

<style scoped lang="scss">
    @import '../styles/vars';

    .bgColor {
        background-color: #516663;
    }

    .no-bgColor {
        background-color: transparent;
    }

    .content-1 {
        height: 100%;
    }

    .ivu-input-group-append, .ivu-input-group-prepend {
        background-color: orange !important;
    }

    .card-content {
        margin: 50px 20vw;
        text-align: center;
        line-height: 30px;

        .row-content {
            text-align: left;
            padding: 10px 10%;

            img {
                width: 140px;
                height: auto;
                margin: 0 15px;
            }
        }

        .red-content {
            font-size: 15px;
            font-weight: bold;
            color: red;

        }

        p {
            font-size: 15px;
        }
    }

    .bg-content {
        content: '';
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 100%;
        background: linear-gradient(0deg, rgba(64, 147, 84, 0.86) 0%, rgba(69, 95, 108, 0.86) 71%, rgba(69, 95, 108, 0.86) 100%), url("../assets/images/promo-bg.jpg") no-repeat center;
        position: absolute;
        /*opacity: 0.3;*/
        background-size: cover !important;
        z-index: 0;
        /*overflow: hidden;*/
    }

    .ivu-menu-item {
        a {
            color: rgba(255, 255, 255, .7);
        }

        .ivu-icon {
            margin-right: 5px;
        }
    }

    .ivu-menu-item-active a {
        color: #fff;
    }

</style>
<template>
    <div class="content-1">
        <Row>
            <i-col span="16" offset="4">
                <p style="font-size: 28px;padding: 10px; text-align: center; ">ZLOG</p>
                <p style="font-size: 18px;padding: 10px; text-align: justify">We continue to Expedite EV Evolution (E<sup>3</sup> Labs) by offering Motor, Motor Controller and Battery System for last mile and point to point express/logistic transportation tools. ZLOG’s expertise of natural cooling 3E system-integration products provide higher performance, more reliable, and easier application for electrical mini-vans, light-trucks, Power-Chassis and AGV etc. Motion Worlds mean that the solution is applicable for virtual simulation world, and for different industrial transportation application worlds also, like EV, AGV, and Intelligence Power Chassis for different hermetic environment etc.
                </p>
                <br>
                <i-input v-if="false" style="width: 35%;margin-left: 10px" v-model="email" placeholder="Your email" >
                    <Icon  slot="prefix" type="md-mail">Send To Us</Icon >
                </i-input>
                <Button type="warning" style="width: 40%" ghost to="/home">GET STARTED</Button>
            </i-col>
        </Row>
    </div>
</template>

<script>
    import {mapMutations} from 'vuex';
    export default {
        components: {
        },
        data () {
            return {
                email: ''
            };
        },
        methods: {
            ...mapMutations(['setCollapsed'])
        },
        mounted () {
        },
        created () {
//            document.body.style.background = "white";
        }
    };

</script>

<style scoped lang="scss">
    @import '../styles/vars';

    .content-1
    {
        /*position: static;*/
        text-align: center;
        color: white;
        height: 100%;
        /*width: 100%;*/
        /*transform: none;*/
        /*margin-left: auto;*/
        /*margin-right: auto;*/
        padding: 18vh 0;
    }

</style>

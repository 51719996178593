import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../components/Home.vue';
import HomeSignUp from '../components/HomeSignUp.vue';
import NotFound from '../components/404.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        name: '',
        hidden: true,
        children: [
            {
                path: '/home',
                component: HomeSignUp
            }
        ],
        redirect: {path: '/home'}
    },
    {
        path: '/404',
        component: NotFound,
        name: '',
        hidden: true
    },
    {
        path: '*',
        hidden: true,
        redirect: {path: '/'}
    }
];

const router = new VueRouter({
    routes
});

export default router
